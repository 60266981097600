import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const BlogCarousel = styled.div`

  .content {
    border-radius: 16px 24px 0 0;
    
    @media ${device.tablet} { 
      top: 31px;
      height: 210px;
    }
    @media ${device.desktopLG} { 
      height: 240px;
    }
  }

  &.p-card {
    padding: 0;
  
    @media ${device.tablet} { 
      padding-right: 24px;
    }
    @media ${device.desktopLG} { 
      padding-right: 10.5px;
      padding-left: 10.5px
    }     
  }

  article {
    display: grid;
    min-height: 350px;

    @media ${device.desktopLG} { 
      min-height: 470px;
    }
    
    @media ${device.desktopLG} { 
      .title {
        height: 77px;
      }
    }
  }

  .title-post {
    font-family: "Sora", Helvetica, sans-serif;
    @media ${device.tablet} { 
      letter-spacing: 0;
    }
    @media ${device.desktopLG} { 
      letter-spacing: 1;
    }
  }
`

export const ImgSize = styled.div`
  img {
    width: 100%;
  }
`
